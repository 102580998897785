@use '../variables' as var;

$level: 10;

@mixin level-spacing($i) {
    padding-right: 14px;

    @if $i >1 {
        padding-left: calc($i - 1) * 12px !important;
    }
}

.file-document {

    .drawer-container,
    .mat-drawer-content {
        height: 100%;
        min-height: calc(100vh - 70px - (25px * 2) - 168px);

        .el-responsive {
            &.view-with-name:hover {
                color: var.$color-primary;
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }

    .drawer-container {
        .fe-icon {
            width: 20px;
            height: 20px;
            min-width: 20px;
            min-height: 20px;
            font-size: 20px;
        }

        .drawer-sidenav {
            mat-tree {
                .tree-node-content {
                    display: flex;
                    align-items: center;
                    flex: 1;
                    word-wrap: break-word;
                    cursor: pointer;

                    .node-name {
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        word-wrap: break-word;
                        word-break: break-all;
                    }

                    .btn-menu {
                        visibility: hidden;
                        height: 100%;
                        width: 24px;
                        position: absolute;
                        right: 0;
                        display: grid;
                        align-items: center;
                        padding: 0 3px;
                        color: #53535f;

                        &:hover {
                            background-color: rgba($color: #53535f, $alpha: 0.05);
                        }
                    }
                }
            }

            .btn-new-folder {
                width: 100%;
            }
        }

        .drawer-sidenav-content {
            height: 100%;
            min-height: calc(100vh - 70px - 50px - 168px);
            background-color: white;
            padding-left: 15px;

            .doc-name-container {
                display: flex;
                align-items: center;

                mat-icon {
                    margin-right: 8px;
                }
            }
        }
    }

    .drawer-sidenav {
        width: 100%;
        max-width: 250px;
    }

    .fd-sidenav {
        padding-right: 12px;

        .mat-tree {
            .mat-tree-node {
                position: relative;
                overflow: hidden;
                height: 34px;
                min-height: 34px;
                border-radius: 4px;
                cursor: pointer;

                &:hover,
                &.e-active {
                    .btn-menu {
                        visibility: visible;
                    }
                }

                &:hover {
                    background-color: #e9ecef;
                    border-color: #e9ecef;
                }

                &.e-active {
                    background-color: #3F419F;
                    color: white;

                    .btn-menu {
                        color: white;
                    }
                }

                .mat-icon-rtl-mirror {
                    width: 20px;
                    height: 20px;
                    min-width: 20px;
                    min-height: 20px;
                    font-size: 20px;
                    margin-left: 8px;
                    margin-right: 12px;
                }
            }

            @for $i from 1 through $level {
                .mat-tree-node {
                    &[aria-level~="#{$i}"] {
                        @include level-spacing($i);
                    }
                }
            }
        }
    }

    .mat-mdc-form-field.mat-form-field-appearance-outline .mat-mdc-form-field-subscript-wrapper {
        display: none;
    }

    @media screen and (max-width: (map-get($map: var.$grid-breakpoints, $key: 'sm') + 100px)) {
        #overview_layout {

            .drawer-sidenav {

                transform: translate3d(-100%, 0, 0) !important;
                visibility: hidden !important;
            }

            .mat-drawer-content {
                margin-left: 0px !important;

                .drawer-sidenav-content {
                    padding-left: 0;
                }
            }
        }

        #overview_toolbar {
            .e-toolbar-left {
                .mat-mdc-button {
                    min-width: 46px !important;
                    padding: 0 6px !important;

                    .mat-icon {
                        height: 25px;
                        width: 25px;
                        font-size: 25px;
                        margin-right: 0;
                    }

                    .mdc-button__label {
                        display: none;
                    }
                }
            }
        }
    }

}

#form {
    .new-folder {

        // .mat-mdc-form-field.mat-form-field-appearance-outline .mat-mdc-form-field-subscript-wrapper {
        //     display: none;
        // }

    }
}

.e-filemanager {
    .e-toolbar {
        min-height: 58px;
        background-color: #F7F8F9;
        border: 1px solid;
        border-color: #F0F0F0;
        border-radius: 5px;
        margin-bottom: 15px;
        padding: 4px;

        .e-toolbar-items {
            &.e-tbar-pos {
                display: flex;
                align-items: center;
                place-content: space-between;

                .e-toolbar-left,
                .e-toolbar-right {
                    display: flex;
                    align-items: center;
                }

                .e-toolbar-left {
                    button {
                        height: 32px !important;
                        min-height: 32px !important;
                        margin: 4px;

                        mat-icon {
                            min-width: 20px;
                            min-height: 20px;
                        }
                    }
                }

                .e-toolbar-right {
                    .toolbar-item {
                        margin: 4px;
                        cursor: pointer;

                        &:not(.filter-search) {
                            min-width: 24px;
                            min-height: 24px;
                            font-size: 24px;
                        }

                        &.disabled {
                            pointer-events: none;
                        }

                        &.is-filter {
                            position: relative;

                            &::after {
                                content: "";
                                width: 8px;
                                height: 8px;
                                background-color: #E91111;
                                border-radius: 50%;
                                position: absolute;
                                top: 0;
                                right: 0;
                            }
                        }

                        .filter-wrapper {
                            .search-box {
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

.custom-dialog {

    mat-dialog-content {
        mat-tab-group {
            .mat-mdc-tab-body {
                .mat-mdc-tab-body-content {
                    overflow: hidden;
                    padding: 0 12px;
                }
            }
        }
    }

    .metadata-container {
        .mat-mdc-form-field.mat-form-field-appearance-outline .mat-mdc-form-field-subscript-wrapper {
            display: none;
        }

        .row-item {
            padding-bottom: 5px;
        }

        .btn-delete {
            width: 20px;
            height: 20px;
            font-size: 20px;
            line-height: 20px;
            cursor: pointer;
        }

        .btn-add-new {
            margin-top: 8px;
        }
    }
}