@use '../variables';

.invalid .drop-zone {
    border-color: #f44336;
}

.drop-zone {
    width: 100%;
    min-height: 80px;
    border-radius: 5px;
    border: 1px dashed rgba(63, 65, 159, 0.2);
    text-align: center;
    margin-bottom: 15px;
    padding: 15px;

    &.fileover {
        border-color: variables.$color-primary;
    }

    .btn-browse {
        color: variables.$color-primary;
        text-decoration: underline;
        cursor: pointer;
    }

    p {
        margin-top: 0;
    }

    .file-name {
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 10px;
    }
}