.approve-request-dialog {

    .detail-form {
        padding: 0;

        .fe-icon {
            width: 20px;
            height: 20px;
            min-width: 20px;
            min-height: 20px;
            font-size: 20px;
            margin-right: 8px;
        }
    }
}