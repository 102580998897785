@use '../variables' as var;

.chart-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  padding: 1rem;
  width: 100%;
  border-radius: var.$rounded-md;

  .card-title {
    margin-right: auto;
    margin-bottom: 10px;
    font-weight: normal;

    &__date {
      font-size: 14px;
      color: var.$silver-chalice;
    }

    &__margin {
      margin-bottom: 16px;
    }

    &__span {
      background-color: rgba($color: #1FDB00, $alpha: 0.1);
      color: #1FDB00;
      padding: 4px 10px;
      border-radius: 25px;
      font-size: 12px;
      font-weight: normal;
    }
  }
}

.doughnut-chart {
  max-width: 200px;
  margin: auto;

  &.half-chart {
    max-height: 170px;
    // margin-top: -40px;
  }
}

.line-chart,
.bar-chart {
  width: 100%;
  margin-top: auto;
}

.doughnut-legend-container {
  // margin-top: 20px;

  .doughnut-label {
    padding: 0;
    margin: 0;

    &__item {
      display: flex;
      align-items: center;
      color: rgba($color: #000000, $alpha: 0.65);
      font-size: 14px;

      &:not(:last-child) {
        margin-bottom: 6px;
      }
    }

    &__dot {
      display: inline-block;
      height: 10px;
      width: 10px;
      min-height: 10px;
      min-width: 10px;
      margin-right: 10px;
      border-radius: 50%;
    }
  }
}

.candidate-count-half-doughnut {
  .canvas-container {
    margin-top: -40px;
    margin-bottom: -40px;
  }
}