@use '../variables' as *;

.home {
  max-width: 2000px;
  margin: 0 auto;
  background-color: inherit;
  .justify-between {
    justify-content: space-between;
  }

  .full-width {
    width: 100%;
  }

  strong {
    opacity: 0.85;
  }

  h1,
  p {
    margin: 0;
  }

  .text-color {
    color: $color-primary;
  }

  .text-blur {
    opacity: 0.8;
  }

  .app-title {
    .group-btn {
      .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align {
        display: none;
      }
      .btn-sm {
        border: 1px solid $mercury-color !important;
        border-radius: $rounded-sm;
        width: 38px;
        height: 38px;
        padding: 6px;
        margin-top: 4px;

        .mat-mdc-button-persistent-ripple {
          border-radius: $rounded-sm;
        }
      }
    }
  }

  .stat-detail {
    height: 100%;
    display: flex;
    align-items: center;

    div {
      margin-left: 1em;
    }

    h1 {
      font-weight: bold;
    }

    h1,
    p {
      color: #eceff3;
      margin: 0;
    }
  }

  .chart-container {
    .title-and-more {
      width: 100%;
    }

    .multi-line-title {
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .legends {
      span {
        &.circle {
          width: 10px;
          height: 10px;
          display: inline-block;
          margin-right: 4px;
          border-radius: $rounded-full;
        }
      }
    }
  }

  .school{
    background-color: #9990FF;
  }
  .class{
    background-color: #17C98E;
  }
  .teacher{
    background-color: #FBAB61;
  }
  .student{
    background-color: #4294FF;
  }

}
