@use 'color' as c;

a,
a:visited,
a:active {
  color: c.$color-text;
  text-decoration: none;
}

h1 {
  // font-size: calc(16px + 2vw);
  // font-size: clamp(28px, 16px + 2vw, 40px);
}
