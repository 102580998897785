@use '../variables' as *;

.group-btn {
  button:not(:last-child) {
    margin-right: 8px;
  }

  .mat-mdc-stroked-button {
    border: none;
  }

  button {
    mat-spinner {
      margin-left: 5px;
      display: inline-block !important;

      circle {
        stroke: #fff !important;
      }
    }
  }
}

.mat-mdc-button.mat-unthemed {
  .mdc-button__label {
    color: $scarpa-flow;
  }
}

/* button small */
.btn-sm {
  min-width: 32px !important;
  border: none !important;
}

/* button large */
.btn-lg {
  width: 300px;
  border-radius: $rounded-sm !important;
  font-size: 20px;
}

//* Tooltip
.custom-tooltip {
  background-color: $color-primary;
  font-size: 11px;
  overflow: visible !important;
  box-shadow: 0px 1px 2px #00000029, 0px 2px 4px #0000001f, 0px 1px 8px #0000001a;
  border-right-color: #fff !important;
  position: relative;

  @media screen and (max-width: map-get($map: $grid-breakpoints, $key: 'sm')) {
    display: none;
  }
}

button.success {
  font-size: 14px;
  color: #fff;
  background-color: $harlequin;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 14px;
    left: 14px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 30px;
    height: 30px;
    opacity: 0;
  }
}
